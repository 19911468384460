import React, { useContext, useEffect, useState } from 'react';

import FeedsTabs from './FeedsTabs';
import FeedItemList from './FeedItemList';
import PostcodePrompt from './PostcodePrompt';

import saveActiveFeed from '../../repositories/saveActiveFeed';
import getFeedItems, { getCurrentPage } from '../../repositories/getFeedItems';
import { trackGAEvent, trackGAEventOnce } from '../../../../utils/gaTracking';
import convertSnakeToPascalCase from './utils/convertSnakeToPascalCase';

import { TFeedItem, TFeeds, TFeed } from 'types/TFeeds';
import LoginDialog from '../../components/LoginDialog/LoginDialog';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';

type TFeedsProps = {
  feeds: TFeeds;
  feedPostcode?: string;
  initialFeed?: number;
};

/**
 * Postcode prompt is shown if feed requires location but user postcode is not known
 */
const shouldShowPostcodePrompt = (feed: TFeed, feedPostcode?: string): boolean =>
  feed?.locationRequired && !feedPostcode;

const SEEN_LOGIN_GATE = 'SeenLoginGate';

const wasLoginDialogShown = () =>
  !!(localStorage.getItem(SEEN_LOGIN_GATE) && localStorage.getItem(SEEN_LOGIN_GATE) === 'true');

const Feeds = ({ feeds, feedPostcode, initialFeed = 0 }: TFeedsProps) => {
  const [activeFeedIndex, setActiveFeedIndex] = useState(initialFeed);
  const [feedsPages, setFeedsPages] = useState({});
  const [items, setItems] = useState<TFeedItem[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false);
  const { userLoggedIn, isBot, hasBannerBeenDismissed } = useContext(EnvironmentContext);

  const fetchFeedItems = async (feed: TFeed, page: number): Promise<void> => {
    setIsLoading(true);

    const feedItems = await getFeedItems({ feed, feedPostcode, page });

    if (feedItems?.length) {
      setItems((prevItems) => [...prevItems, ...feedItems]);
      setHasNextPage(true);
    } else {
      // having no items back means feed-api is either broken or has no data for feed
      // anyway infinite scroll should be disabled in this case
      setHasNextPage(false);
    }

    if (page === 0) {
      const eventLabel = convertSnakeToPascalCase(feed.feedType);
      if (feedItems.length) {
        trackGAEvent('Displayed', eventLabel);
        trackGAEventOnce('FirstLoadNotEmpty', eventLabel);
      } else {
        trackGAEvent('EmptyStateDisplayed', eventLabel);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!feeds.length) {
      return;
    }

    const pages =
      feeds.length &&
      feeds.reduce(
        (acc, feed) => ({
          ...acc,
          [feed.feedType]: getCurrentPage({ feed, feedPostcode }),
        }),
        {},
      );
    const feed = feeds[activeFeedIndex];

    setFeedsPages(pages);
    setActiveFeedIndex(activeFeedIndex);
    saveActiveFeed(activeFeedIndex);

    const eventLabel = convertSnakeToPascalCase(feed.feedType);
    trackGAEvent('TabSelected', eventLabel);

    const page = pages[feed.feedType];
    if (page === 0) {
      trackGAEvent(`LoadMore${page}`, eventLabel);
    }

    // try to restore state from cache or fetch from server
    fetchFeedItems(feed, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isBot && !userLoggedIn && hasBannerBeenDismissed && !wasLoginDialogShown()) {
      setShowLoginDialog(true);
      localStorage.setItem(SEEN_LOGIN_GATE, 'true');
    }
  }, [userLoggedIn, isBot, hasBannerBeenDismissed]);

  const handleLoadMore = (pageIncrement = 1) => {
    const feed = feeds[activeFeedIndex];
    const page = feedsPages[feed.feedType] + pageIncrement;

    setFeedsPages((prevPages) => ({
      ...prevPages,
      [feed.feedType]: page,
    }));

    trackGAEvent(`LoadMore${page}`, convertSnakeToPascalCase(feed.feedType));
    // fetch new items
    fetchFeedItems(feed, page);
  };

  const handleFeedChange = (index: number) => {
    setActiveFeedIndex(index);
    saveActiveFeed(index);
    // reset items when switching between tabs
    setItems([]);
    setHasNextPage(true);

    const feed = feeds[index];
    const page = feedsPages[feed.feedType];
    const eventLabel = convertSnakeToPascalCase(feed.feedType);
    trackGAEvent('TabSelected', eventLabel);

    if (!shouldShowPostcodePrompt(feeds[index], feedPostcode)) {
      if (page === 0) {
        trackGAEvent(`LoadMore${page}`, eventLabel);
      }
      // try to restore state from cache or fetch from server
      fetchFeedItems(feed, page);
    }
  };

  if (!feeds.length) {
    return null;
  }

  return (
    <>
      <FeedsTabs feeds={feeds} activeFeedIndex={activeFeedIndex} onClick={handleFeedChange} />
      {shouldShowPostcodePrompt(feeds[activeFeedIndex], feedPostcode) ? (
        <PostcodePrompt />
      ) : (
        <FeedItemList
          activeFeedIndex={activeFeedIndex}
          items={items}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          onLoadMore={handleLoadMore}
          feedType={feeds[activeFeedIndex].feedType}
        />
      )}
      {showLoginDialog && <LoginDialog onClose={setShowLoginDialog} />}
    </>
  );
};

export default Feeds;
